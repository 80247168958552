import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { DeleteVisitsModal } from '../DeleteVisitsModal/DeleteVisitsModal';
import { useDeleteVisits } from '../../hooks/ReactQuery/Visits/useDeleteVisits';

interface PropsType {
  isOpen: boolean;
  setIsOpen: (prevState: boolean) => void;
}

type DateRange = {
  from: Date | undefined;
  to?: Date | undefined;
};

const VisitDelete: React.FC<PropsType> = ({ isOpen, setIsOpen }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<DateRange | undefined>();

  const { deleteVisitsMutate } = useDeleteVisits();

  const onClose = () => {
    setIsOpen(!isOpen);
  };

  const onConfirm = async () => {
    setLoading(true);
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await deleteVisitsMutate(data);
    } catch (error) {
      toast.error('Something went wrong');
    } finally {
      setLoading(false);
      setIsOpen(false);
    }
    console.log('onConfirm', data);
  };
  return (
    <div>
      <DeleteVisitsModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onConfirm}
        loading={loading}
        setData={setData}
      />
    </div>
  );
};

export default VisitDelete;
