import { useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '../../../redux/app/hooks';
import shownErrorMessage from '../../../helpers/shownErrorMessage';
import { setError } from '../../../redux/features/errorSlice';
import { paymentProviderApi } from '../../../api/services/paymentProvider';

export const useGetPaymentProvider = (pageSize, currentPage, searchValue) => {
  const dispatch = useAppDispatch();

  // eslint-disable-next-line consistent-return
  const queryFunction = async () => {
    try {
      const response = await paymentProviderApi.getAllPaymentProviders(
        pageSize,
        currentPage,
        searchValue
      );
      return response.data;
    } catch (error) {
      const errorMessage = shownErrorMessage(error);
      dispatch(setError({ type: errorMessage, error }));
    }
  };

  return useQuery({
    queryKey: ['paymentProviders', pageSize, currentPage, searchValue],
    queryFn: () => queryFunction(),
  });
};
