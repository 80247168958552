import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { FormCreator } from '../../components';
import { paymentSystemUpdateValidation, paymentSystemValidation } from './paymentSystemValidation';
import { usePaymentSystemCreatorFields } from './usePaymentSystemCreatorFields';
import { paymentSystemApi } from '../../api/services/paymentSystem';

interface optionType {
  value: string;
  label: string;
}

const PaymentSystemNew: React.FC = () => {
  const queryClient = useQueryClient();
  const { uuid } = useParams();
  const location = useLocation();

  const [data, setData] = useState<any>(null);
  const [paymentProviderOptions, setPaymentProviderOptions] = useState<optionType[]>([]);
  const allPaymentProviders: any = queryClient.getQueryData(['allPaymentProviders']);

  useEffect(() => {
    setPaymentProviderOptions([]);
    allPaymentProviders?.paymentProviders?.forEach((paymentProvider: any) => {
      setPaymentProviderOptions((prevState) => [
        ...prevState,
        { value: paymentProvider?.uuid, label: paymentProvider?.name },
      ]);
    });
  }, [allPaymentProviders]);

  useEffect(() => {
    if (uuid) {
      const getDate = async () => {
        const {
          data: { paymentSystem },
        } = await paymentSystemApi.getPaymentSystemByUuid(uuid);

        setData(paymentSystem);
      };

      getDate().catch((error) => console.error('error', error));
    }
  }, [uuid]);

  return (
    ((uuid && data) || location.pathname.includes('/new')) && (
      <FormCreator
        title="Payment System"
        description="Create a payment system"
        validation={data ? paymentSystemUpdateValidation : paymentSystemValidation}
        fields={usePaymentSystemCreatorFields({ paymentProviderOptions })}
        submitFunction={
          data ? paymentSystemApi.updatePaymentSystem : paymentSystemApi.createPaymentSystem
        }
        initialData={data}
      />
    )
  );
};

export default PaymentSystemNew;
