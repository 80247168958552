import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { pageContentEn } from '../../../constants/pageContent';
import shownErrorMessage from '../../../helpers/shownErrorMessage';
import { courseApi } from '../../../api/services/courses';

export const useUpdateCourseVideoList = () => {
  const queryClient = useQueryClient();

  // eslint-disable-next-line consistent-return
  const mutationFunction = async (courseUuid: string) => {
    try {
      const response = await courseApi.updateCourseInfoByUuid(courseUuid);
      toast.success(
        response?.data?.message || pageContentEn.COURSE_INFORMATION_UPDATED_SUCCESSFULLY
      );
      return response;
    } catch (error) {
      const errorMessage = shownErrorMessage(error);
      toast.error(errorMessage);
    }
  };

  const { mutateAsync: updateCourseInformationMutate } = useMutation({
    mutationFn: mutationFunction,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['courses'] });
    },
  });

  return { updateCourseInformationMutate };
};
