import { ColumnDef } from '@tanstack/react-table';
import { CellAction } from '../../components/DataTable/CellAction/CellAction';
import { priceApi } from '../../api/services/price';

export type ColumnType = {
  image: string;
  name: string;
  isShown: string;
};

export const usePaymentProviderTableColumns: ColumnDef<ColumnType>[] = [
  { accessorKey: 'image', header: 'image' },
  { accessorKey: 'name', header: 'Name' },
  { accessorKey: 'isShown', header: 'isShown' },
  {
    id: 'action',
    cell: ({ row }) => <CellAction data={row.original} deleteAction={'paymentProvider'} />,
  },
];
