import React, { useState } from 'react';

import { Button } from '../../@/components/ui/button';
import { AlertModal } from '../../components/AlertModal/AlertModal';
import { courseApi } from '../../api/services/courses';
import shownErrorMessage from '../../helpers/shownErrorMessage';
import { setError } from '../../redux/features/errorSlice';
import { useAppDispatch } from '../../redux/app/hooks';

const Settings = () => {
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const confirmHandler = async () => {
    setLoading(true);
    try {
      const response = await courseApi.updateCoursesInfo();

      setIsOpen(false);
      return response.data;
    } catch (error) {
      const errorMessage = shownErrorMessage(error);
      dispatch(setError({ type: errorMessage, error }));
    } finally {
      setLoading(false);
    }
    return null;
  };

  const handleClick = () => {
    setIsOpen(true);
  };

  const closeHandler = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="flex flex-col">
        <h2 className="text-3xl font-bold tracking-tight">Settings</h2>
        <p className="text-sm text-muted-foreground">General settings</p>
        <div className="flex flex-col mt-5">
          <p className="text-sm text-muted-foreground mb-2">Update course video information</p>
          <Button className="bg-blue-500" onClick={handleClick}>
            Update course information
          </Button>
        </div>
      </div>
      <AlertModal
        isOpen={isOpen}
        onClose={closeHandler}
        loading={loading}
        onConfirm={confirmHandler}
      />
    </>
  );
};

export default Settings;
